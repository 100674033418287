body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background: #000000;
}

::-webkit-scrollbar-thumb {
  background: rgb(97, 49, 49);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(68, 41, 37);
}

::-webkit-scrollbar-track {
  background: #000000;
}

::-webkit-scrollbar-thumb {
  background: rgb(97, 49, 49);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(68, 41, 37);
}

html {
  scrollbar-width: thin;
  scrollbar-color: rgb(97, 49, 49) black;
}

body {
  background: #0f0f0f;
}

button {
  background-color: rgb(200, 200, 200);
  border: solid 1px #000000;
  text-align: center;
  text-decoration: none;
}

